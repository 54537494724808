
.dropdown_selection_box {
  position: relative;
  height: 169px;
  overflow: auto;
}

.dropdown_menu {
  position: absolute;
}
