.primary {
  width: 154px;
  height: 48px;
  border-radius: 3px;
  background: #006699 0% 0% no-repeat padding-box;
  color: white;
}

.primary:focus {
  width: 154px;
  height: 48px;
  border-radius: 3px;
  background: #00476b 0% 0% no-repeat padding-box;
  border: 3px solid #1d89bf;
  opacity: 1;
}

.primary:hover {
  width: 154px;
  height: 48px;
  border-radius: 3px;
  background: #00476b 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #0000004d;
  opacity: 1;
}

.secondary {
  width: 154px;
  height: 48px;
  border-radius: 3px;
  border: 2px solid #1d89bf;
  background: #fff;
  color: #006699;
}

.secondary:focus {
  background: #e6f5fd 0% 0% no-repeat padding-box;
  border: 3px solid #1d89bf;
  color: #003366;
}

.secondary:hover {
  background: #e6f5fd 0% 0% no-repeat padding-box;
  color: #003366;
}

.tertiary {
  color: #006699;
  width: auto;
  height: 48px;
}

.tertiary:hover {
  width: auto;
  height: 48px;
  color: #00476b;
  background-color: #f9f9f9;
}

.tertiary:focus {
  width: auto;
  height: 48px;
  border: 3px solid #1d89bf;
  color: #00476b;
  background-color: #f9f9f9;
}

.cancel {
  width: 6em;
  height: 48px;
  color: #006699;
}

.cancel:hover {
  width: 6em;
  height: 48px;
  color: #012638;
  text-decoration: underline;
}

.cancel:focus {
  width: 6em;
  height: 48px;
  color: #012638;
  text-decoration: underline;
}

.signout-button {
  color: #ffffff;
  width: auto;
  height: 48px;
}

*:disabled {
  background-color: #ebebeb;
  color: black;
}

*:disabled:hover {
  background-color: #ebebeb;
  color: black;
}

.return {
  height: 48px;
  border-radius: 3px;
  padding: 0px 30px;
  background: #edeeee 0% 0% no-repeat padding-box;
  color: black;
}

.return:focus {
  background: #d9d9d9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.return:hover {
  background: #d9d9d9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #0000004d;
  opacity: 1;
}
