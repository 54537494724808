.passkey-list-row {
  padding: 15px 0px 30px 0px;
  border-bottom: solid 1px #929395;
}

.passkey-actions-btn {
  border: solid 2px black;
  color: black;
  width: 50px;
  height: 30px;
  font-size: 30px;
  line-height: 0.5;
  padding-bottom: 15px;
}

.temp-remove-btn {
  border: dotted 1px grey;
  color: grey;
  width: 75px;
  height: 30px;
  font-size: 10 px;
}
