.edit-account-button {
  border: 1px solid currentColor;
  border-radius: 3px;
}

.edit-account-button:hover {
  background: #e6f5fd 0% 0% no-repeat padding-box;
  color: #003366;
}

button:focus .edit-account-button {
  background: #e6f5fd 0% 0% no-repeat padding-box;
  color: #003366;
}

.edit-account-button-primary {
  border: 1px solid currentColor;
  border-radius: 3px;
  background: #006699 0% 0% no-repeat padding-box;
  color: #ffffff !important;
}

.edit-account-button-primary svg path {
  fill: #ffffff;
}

.edit-account-button-primary:hover {
  border: 1px solid currentColor;
  border-radius: 3px;
}

.edit-account-button-primary:hover svg path {
  fill: #006699;
}

/* .secondary:focus {
  background: #e6f5fd 0% 0% no-repeat padding-box;
  border: 3px solid #1d89bf;
  color: #003366;
}

.secondary:hover {
  background: #e6f5fd 0% 0% no-repeat padding-box;
  color: #003366;
} */

@media (max-width: 640px) {
  .edit-account-button {
    border: none;
    border-radius: 0px;
  }
}

.color-light-grey {
  color: #595959;
}
