.animate-spin {
    animation: spin 1.3s linear infinite !important;
    /* animation-duration: 1s !important;
    animation-timing-function: linear !important;
    animation-delay: 0s !important;
    animation-iteration-count: infinite !important; */
    /* animation-direction: reverse !important; */
    /* animation-fill-mode: none !important;
    animation-play-state: running !important;
    animation-name: spin !important;
    animation-timeline: auto !important;
    animation-range-start: normal !important;
    animation-range-end: normal !important; */
}