.fingerprint-icon {
  width: 25%;
}

.sign-in-faster {
  font: Source Sans Pro;
  font-weight: 600;
  font-size: 20px;
}

.sign-in-securely {
  font: Source Sans Pro;
  font-weight: 400;
  font-size: 16px;
}
