.application-modal {
  width: calc(100vw - 40px);
  max-width: 1200px;
}

.application-modal-view {
  width: calc(100vw - 40px);
  max-width: 800px;
}

.multi-line-input .set-fields {
  max-height: 310px;
  overflow-y: auto;
}

.multi-line-view {
  max-height: 500px;
  overflow-y: auto;
}

.question-drop-down-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border-width: 1px;
  width: 100%;
  outline: none;
  padding: .5em;
  border-color: rgb(209 213 219);
}

.question-drop-down-input:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #006699;
  border: 1px solid #cccccc;
  border-radius: 3px;
  outline: none;
  opacity: 1;
}

.question-drop-down-input:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #006699;
  border: 1px solid #cccccc;
  border-radius: 3px;
  outline: none;
  opacity: 1;
}

