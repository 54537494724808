:root {
  --sectional-alert-icon-width: 20px;
}

.sectional_alert_success {
  min-height: 52px;
  border-left: #006600 16px solid !important;
}
.sectional_alert_warning {
  min-height: 52px;
  border-left: #ffb500 16px solid !important;
}
.sectional_alert_error {
  min-height: 52px;
  border-left: #c41230 16px solid !important;
}
.sectional_alert_info {
  min-height: 52px;
  border-left: #003366 16px solid !important;
}

.sectional-alert-icon {
  width: calc(var(--sectional-alert-icon-width) + 8px);
}

.sectional-alert-body {
  width: calc((100% - var(--sectional-alert-icon-width)) - 8px);
}
