.dashboard-link {
  width: 154px;
  max-width: 100%;
  height: 48px;
  border-radius: 3px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-align: center;
  font-weight: normal;
  background: #006699 0% 0% no-repeat padding-box;
  color: white;
}

.dashboard-link:focus {
  width: 154px;
  height: 48px;
  border-radius: 3px;
  background: #00476b 0% 0% no-repeat padding-box;
  border: 3px solid #1d89bf;
  opacity: 1;
}

.dashboard-link:hover {
  width: 154px;
  height: 48px;
  border-radius: 3px;
  background: #00476b 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #0000004d;
  opacity: 1;
}

.dashboard-card-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.dashboard-card {
  display: flex;
  flex-direction: column;
  flex: 1 0 calc(50% - 16px);
}

.dashboard-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 112px);
}

.dashboard-card-header {
  display: flex;
  justify-content: center;
}

@media (max-width: 639px) {
  .dashboard-card {
    flex: 1 0 100%;
  }
}

@media (min-width: 640px) {
  .dashboard-card {
    max-width: calc((50% - 16px));
  }
}

@media (min-width: 768px) {
  .dashboard-card {
    flex-direction: row;
    flex: 1 1 calc((50% - 16px));
    width: 176px;
  }

  .dashboard-card-header {
    height: 100%;
    min-width: 140px;
  }

  .dashboard-card-body {
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .dashboard-card-header {
    height: 100%;
    min-width: 176px;
  }
}
