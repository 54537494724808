
.checkbox-input {
  opacity: 0;
  height: 20px;
  width: 20px;
}
  
.custom-checkbox {
  height: 20px;
  width: 20px;
  accent-color: #006699;
  border: 1px solid #d1d5db;
  border-radius: 3px;
  position: absolute;
  pointer-events: none;
}

.custom-checkbox.checked {
  background-color: #006699;
}

.custom-checkbox.has-focus, .custom-checkbox.hovering {
  box-shadow: 0px 0px 5px #006699;
  border: 3px solid d1d5db;
  border-radius: 3px;
}

.checkmark {
  width: 100%;
  height: 100%;
  transform: rotate(45deg) translate(-40%, -20%);
}

.checkmark-body {
  bottom: 0;
  right: 0;
  width: 15%;
  height: 80%;
  background-color: #ffffff;
  position: absolute;
}

.checkmark-foot {
  bottom: 0;
  right: 0;
  width: 40%;
  height: 15%;
  background-color: #ffffff;
  position: absolute;
}