.status-bar {
  min-height: 3rem;
}

.status-info {
  background-color: #e5eaef;
}

.status-warn {
  background-color: #fff8e5;
}

.status-urgent {
  background-color: #faeaea;
}

.status-dismiss-button:hover,
.status-dismiss-button:focus {
  background-color: #f9f9f9;
  color: #00476b;
}
