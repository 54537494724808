.idp-card-ol{
    list-style-type: upper-roman;
    list-style-position: inside;
}

.idp-card-ol-letter{
    list-style-type:upper-alpha;
    list-style-position: inside;
}

.idp-card-ol-number{
    list-style-type:decimal;
    list-style-position: inside;
}


.idp-card-bullet{
    /* list-style-type: disc;
    list-style-position: outside;
    padding-left: 1em; */
    list-style-type: none;
}

.idp-card-bullet > li {
    text-indent: 5px;
}

.idp-card-bullet > li:before {
    content: "- ";
    text-indent: 5px;
}
