:root {
  --font-icon-size: 1em;
}



.external-link::after {
  mask: url(../../assets/icons/new-page-icon.svg);
  mask-repeat: no-repeat;
  mask-size: var(--font-icon-size);
  height: var(--font-icon-size);
  width: var(--font-icon-size);
  content: "";
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
  background: currentColor;
}