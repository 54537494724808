@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: '16px';
  }
}

@layer components {
  html {
    @apply h-screen bg-dhs_gray_20;
  }

  .header {
    @apply flex mx-auto px-8 items-end justify-between bg-gray shadow-md;
  }

  .nav {
    @apply relative inline-flex h-32 align-top col-span-full;
  }

  .title {
    @apply mt-10 mb-4 ml-80 leading-4 text-blue;
  }

  .title-md {
    @apply mt-10 mb-4 mx-40 leading-4 text-blue;
  }

  .nav-list {
    @apply hidden flex flex-row mt-24 space-x-4 align-bottom rounded bg-gray;
  }

  .logo {
    @apply h-44 w-44 align-baseline;
  }

  .dropDownMenu {
    @apply origin-center relative right-0 mt-2 w-36 rounded-md shadow-lg bg-white divide-y divide-blue focus:outline-none;
  }

  .social-media-icon {
    @apply h-12 w-12 p-2 sm:my-1 bg-dhs-socialmedia;
  }

  .ext-link {
    @apply hover:text-white focus:ring focus:ring-dhs_light_blue outline-none;
  }

  .ext-link-li {
    @apply h-8 sm:w-1/2 lg:w-full;
  }

  .nav-link {
    @apply px-2 text-dhs-blue-01 hover:text-dhs_focus_blue underline focus:ring focus:ring-dhs_light_blue outline-none;
  }

  .table-style {
    @apply border border-solid border-dhs-gray-00 p-4;
  }

  .table-header-style {
    @apply text-left mt-6 mb-6 ml-0 mr-0 font-bold;
  }

  .table-rows-style {
    @apply odd:bg-dhs-gray-20 even:bg-white;
  }

  .primary-button-style {
    @apply h-8 w-auto text-md font-bold focus:ring focus:ring-dhs_light_blue outline-none bg-dhs-blue-03 text-white p-5;
  }

  .grid-style {
    @apply p-4 outline-none focus:ring-2 focus:ring-dhs_light_blue;
  }

  .card {
    @apply m-auto p-4 sm:p-8 sm:w-593 rounded-sm overflow-auto shadow-md mx-auto bg-white;
  }

  .card-header {
    @apply pt-4 pb-4 text-gray-60 text-2xl text-left;
  }

  /* .card-body {
    @apply pb-8;
  } */

  .card-footer {
    @apply p-3 text-dhs-blue text-2xl text-center border-b-2 flex items-center;
  }

  .radio-boxed {
    @apply w-full my-4 mx-0 py-2 rounded-sm border-2;
  }

  .skiplink {
    @apply absolute top-2 left-2;
  }

  .terms-heading {
    @apply text-2xl text-dhs-blue-01 my-4;
  }
  .terms-subheading {
    @apply text-2xl my-5 text-dhs-blue;
  }
  .terms-num-subheading {
    @apply text-dhs-blue-01 font-bold;
  }
  .terms-paragraph {
    @apply my-4;
  }

  /* I probably want to remove some of these. */

  .idp-card {
    @apply card mx-auto;
  }

  .idp-card h1 {
    @apply text-2xl my-3 font-normal text-dhs_font_gray;
  }

  .idp-card h2 {
    @apply text-xl;
  }

  .idp-card h3 {
    @apply mt-8 mb-6 text-xl;
  }

  .idp-card p {
    @apply mt-2 mb-2;
  }
  .text-paragraph {
    @apply my-4;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
  }

  @media (max-width: 640px) {
    .button-container {
      flex-direction: column;
    }

    .button-container button {
      @apply w-full;
    }

    .button-container button:hover {
      @apply w-full;
    }

    .button-container button:focus {
      @apply w-full;
    }
  }
}
