.external-links-container {
  display: flex;
  flex-direction: row;
}

.external-links-column {
  display: flex;
  flex-direction: column;
}

@media (max-width: 640px) { 
  .external-links-container a {
    margin: 0.5rem;
  }
 }