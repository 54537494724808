.more-info-container {
  display: block;
  overflow: hidden;
  background-color: #f4f4f4;
}

.more-info-container.opened {
  transition: all 1s;
}

.more-info-container.closed {
  max-height: 0px;
  transition: all 1s;
}

.more-info-content {
  border: 1px solid rgb(209 213 219);
  border-radius: 4px;
}

.more-info-content-icon {
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  margin-top: 18px;
  margin-left: 16px;
  margin-right: 12px;
}

.more-info-toggle-icon {
  translate: 4px 4px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  pointer-events: auto;
  color: #006699;
}

.more-info-toggle-icon:hover,
.more-info-toggle-icon:focus {
  box-shadow: 0px 0px 3px #006699;
  border: 1px solid #cccccc;
  border-radius: 50%;
  outline: none;
  opacity: 1;
  height: 21px;
  width: 21px;
  translate: 4px 4px;
  padding-bottom: 0px;
  color: #00476b;
}

.more-info-content-toggle-icon {
  translate: 4px 4px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  pointer-events: auto;
  color: #006699;
}

.more-info-content-toggle-icon:hover,
.more-info-content-toggle-icon:focus {
  box-shadow: 0px 0px 3px #006699;
  border: 1px solid #cccccc;
  border-radius: 50%;
  outline: none;
  opacity: 1;
  height: 21px;
  width: 21px;
  translate: 4px 4px;
  padding-bottom: 0px;
  color: #00476b;
}

.more-info-close-button {
  height: fit-content;
}

.more-info-close-button:hover,
.more-info-close-button:focus {
  box-shadow: 0px 0px 3px #006699;
  outline: 1px solid #cccccc;
  border-radius: 3px;
  opacity: 1;
  color: #00476b;
}
