.terms-of-use-ol{
    list-style-type: upper-roman;
    list-style-position: inside;
}

.terms-of-use-ol-letter{
    list-style-type:upper-alpha;
    list-style-position: inside;
}

.terms-of-use-ol-number{
    list-style-type:decimal;
    list-style-position: inside;
}


.terms-of-use-bullet{
    /* list-style-type: disc;
    list-style-position: outside;
    padding-left: 1em; */
    list-style-type: none;
}

.terms-of-use-bullet > li {
    text-indent: 5px;
}

.terms-of-use-bullet > li:before {
    content: "- ";
    text-indent: 5px;
}

