#login-gov-btn {
  div {
    height: 20px;
    width: 80px;
  }
  img {
    height: 30px;
    width: 120px;
  }
}
