#env-banner {
  position: fixed;
  top: 0;
  left: 0;
  transform: rotate(-45deg) translate(-50px, -30px);
  z-index: 999;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.2);
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
  color: white;

  &.env-color-preview {
    transform: rotate(-45deg) translate(-55px, -30px);
    font-size: 1.1rem !important;
  }
}

.env-color-dt {
  background: linear-gradient(
    to right,
    #f5af19,
    #f12711
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.env-color-dev {
  background: linear-gradient(
    to right,
    #00d2ff,
    #3a47d5
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.env-color-local {
  background: linear-gradient(
    to right,
    #38ef7d,
    #11998e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.env-color-pt {
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.env-color-preview {
  background: linear-gradient(
    to right,
    #ffdf99,
    #d9a501
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* z-index: 100000; */
