.validated_input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border-width: 1px;
  width: 100%;
  outline: none;
  border-color: rgb(209 213 219);
}

.validated_input:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #006699;
  border: 1px solid #cccccc;
  border-radius: 3px;
  outline: none;
  opacity: 1;
}

.validated_input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.outline-none:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
